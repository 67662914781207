
import React from 'react';
import { BubbleChat } from 'flowise-embed-react'
// components
// import Header from './components/Header';
import TelescopSection from './components/TelescopSection';
import Features from './components/Features';
import Process from './components/Process';
import CarouselComponent from './components/CarouselComponent';
import Accuracy from './components/Accuracy';
import Technology from './components/Technology';
import TeamSection from './components/TeamSection';
import Collaboration from './components/Collaboration';
import Footer from './components/Footer';

declare global {
  interface Window {
    Chatbot: any;
  }
}

const App: React.FC = () => {

  return (
    <div className="App">
      {/* <Header /> */}
      <TelescopSection />
      <div className='backgroundRadius' >
        <div className='componentsContainer' >
          <Features />
          <Process />
        </div>
      </div>
      <Accuracy />
      <CarouselComponent />
      <Technology />
      <TeamSection />
      <Collaboration />
      <Footer />
      <BubbleChat
        chatflowid="ab0d4f9c-f3ea-498a-bc58-c60c7b38577a"
        apiHost="https://admin.reblox.sk"
        // chatflowConfig={{
        // }}
        // observersConfig={{

        // }}
        theme={{    
          button: {
          backgroundColor: '#2e2f33',
          right: 20,
          bottom: 20,
          size: 48,
          dragAndDrop: true,
          iconColor: 'white',
          // customIconSrc: 'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
          autoWindowOpen: {
            autoOpen: false,
            openDelay: 2,
            autoOpenOnMobile: false
          }
          },
          tooltip: {
            showTooltip: false,
            tooltipMessage: 'Hi There 👋!',
            tooltipBackgroundColor: 'black',
            tooltipTextColor: 'white',
            tooltipFontSize: 16
          },
          disclaimer: {
            title: 'Disclaimer',
            message: "Používaním tohto chatbota súhlasíte s <a target=\"_blank\" href=\"https://api.taxpilot.eu/documents/vop.pdf\">podmienkami používania služby</a>",
            textColor: 'black',
            buttonColor: '#2e2f33',
            buttonText: 'Začať četovať',
            buttonTextColor: 'white',
            blurredBackgroundColor: 'rgba(0, 0, 0, 0.4)',
            backgroundColor: 'white',
            denyButtonText: 'Zrušiť',
            denyButtonBgColor: '#ef4444',
          },
          customCSS: ``,
          chatWindow: {
            showTitle: true,
            showAgentMessages: true,
            title: 'TaxPilot Assistant',
            // titleAvatarSrc: 'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
            welcomeMessage: 'Dobrý deň, ako vám možem pomôcť?',
            errorMessage: 'Nastala neočakávaná chyba!',
            backgroundColor: '#ffffff',
            backgroundImage: 'enter image path or link',
            height: 700,
            width: 430,
            fontSize: 16,
            starterPrompts: [
              "Čo je TaxPilot?",
              "Aké sú výhody TaxPilot?",
              "Pre koho je TaxPilot určený?",
            ],
            starterPromptFontSize: 15,
            clearChatOnReload: false,
            sourceDocsTitle: 'Zdroje:',
            renderHTML: true,
            botMessage: {
              backgroundColor: '#f7f8ff',
              textColor: '#303235',
              showAvatar: true,
              avatarSrc: '/images/bot4.png'
            },
            userMessage: {
              backgroundColor: '#2e2f33',
              textColor: '#ffffff',
              showAvatar: true,
              avatarSrc: 'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png'
            },
            textInput: {
              placeholder: 'Napíšte otázku',
              backgroundColor: '#ffffff',
              textColor: '#303235',
              sendButtonColor: '#2e2f33',
              maxChars: 50,
              maxCharsWarningMessage: 'Prekročili ste povolený počet znakov. Zadajte menej ako 50 znakov.',
              autoFocus: true,
              sendMessageSound: false,
              receiveMessageSound: false
            },
            feedback: {
              color: '#303235'
            },
            dateTimeToggle: {
              date: true,
              time: true
            },
            footer: {
              textColor: '#303235',
              text: '',
              company: 'Reblox',
              companyLink: 'https://art4.sk/'
            }
          }
          }
        }
      />
    </div>
  );
}

export default App;

