
import React from 'react';

import '../css/process.css';

const Process: React.FC = () => {
    return (
        <section className="container">
            <div className="content">
                <h2>HOW DOES TAX PILOT WORK?</h2>
                <ul className="features-list">
                    <li>
                        <div className="process-number">1</div>
                        <div className="process-text">
                            <h2>Speed</h2>
                            <p>Get answers to your questions faster than ever before.</p>
                        </div>
                    </li>
                    <li>
                        <div className="process-number">2</div>
                        <div className="process-text">
                            <h2>Accuracy</h2>
                            <p>The accuracy of the generated answer is manually verified by experts.</p>
                        </div>
                    </li>
                    <li>
                        <div className="process-number">3</div>
                        <div className="process-text">
                            <h2>Price</h2>
                            <p>Costs are significantly lower compared to standard consultations with human experts.</p>
                        </div>
                    </li>
                    <li>
                        <div className="process-number">4</div>
                        <div className="process-text">
                            <h2>Availability</h2>
                            <p>No appointments, your personal tax advisor is available to you anytime, from any device, for an unlimited number of consultations.</p>
                        </div>
                    </li>
                    <li>
                        <div className="process-number">5</div>
                        <div className="process-text">
                            <h2>Reliability</h2>
                            <p>Based on the latest AI technologies and updated legal regulations.</p>
                        </div>
                    </li>
                    <li>
                        <div className="process-number">6</div>
                        <div className="process-text">
                            <h2>Security</h2>
                            <p>Your questions, answers, and information about your business are confidentially protected.</p>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="imageProcess">
                <img src="/images/labak.jpeg" alt="process" />
            </div>
        </section>

    );
}

export default Process;
